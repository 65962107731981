import React, {
  useState,
  useCallback,
  forwardRef,
  useContext,
  useEffect,
} from "react";
import { useForm } from "@formspree/react";
import Slider from "react-slick";
import styled from "styled-components";
import { useIntl } from "gatsby-plugin-intl";
import { Container, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { FormModalContext } from "../../providers/FormModalProvider";
import useQuestions from "./useQuestions";
import Back from "../../images/back.inline.svg";
import Happiness from "../../images/happiness.inline.svg";

const StyledSlider = styled(Slider)`
  .slick-track {
    display: flex;
    align-items: center;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  border: 0;
  border-bottom: solid 1px #000;
  background: transparent;
  &:focus {
    outline: none;
  }
`;

const BackBtn = styled.button`
  background: transparent;
  border: 0;
  svg {
    width: 25px;
    height: auto;
  }
`;

const StyledOption = styled.button`
  width: 100%;
  height: 100%;
  border: solid 1px #000;
  background: transparent;
  padding: 50px 15px;
  display: block;
  &.selected {
    background-color: #000;
    color: #fff;
    svg {
      path,
      rect {
        fill: #fff;
      }
    }
  }
  svg {
    width: 50px;
    height: auto;
  }
`;

const HappinessIcon = styled(Happiness)`
  width: 50%;
  max-width: 50px;
  height: auto;
`;

const Button = styled.button`
  border: solid 1px #000;
  background: transparent;
  padding: 15px 30px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: #000;
    color: #fff;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Questionnaire = ({ type }) => {
  const { setModalState } = useContext(FormModalContext);
  const [state, handleSubmit] = useForm(process.env.GATSBY_FORMSPREE_FORM_ID);
  const { succeeded } = state;
  const [formData, setFormData] = useState({ name: "", phone: "", email: "" });
  const {
    projectQuestionsStart,
    visualizationQuestions,
    projectConstructionQuestions,
    projectFinalQuestions,
    investorQuestions,
  } = useQuestions();
  const [slider, setSlider] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const intl = useIntl();
  const sliderRef = useCallback(node => {
    if (node !== null) {
      setSlider(node);
    }
  }, []);

  // Show thank you slide when form submits
  useEffect(() => {
    if (succeeded) {
      slider.slickNext();
    }
  }, [succeeded, setModalState, slider]);

  const projectSpecificQuestions =
    formData.type === intl.formatMessage({ id: "visualization" })
      ? visualizationQuestions
      : projectConstructionQuestions;

  const questions =
    type === "project"
      ? [
          ...projectQuestionsStart,
          ...projectSpecificQuestions,
          ...projectFinalQuestions,
        ]
      : [...investorQuestions];

  const sliderSettings = {
    infinite: false,
    draggable: false,
    arrows: false,
    beforeChange: (_current, next) => {
      setCurrentSlide(next);
    },
  };

  const contactData = [
    {
      id: "name",
      placeholder: intl.formatMessage({ id: "full_name" }),
      type: "text",
    },
    {
      id: "phone",
      placeholder: intl.formatMessage({ id: "phone" }),
      type: "tel",
    },
    {
      id: "email",
      placeholder: intl.formatMessage({ id: "email" }),
      type: "email",
    },
  ];

  const onClick = (question, option) => {
    if (!question.submitBtn && !option.date) {
      slider.slickNext();
    }
    setFormData(prevData => ({
      ...prevData,
      [question.id]: option.title,
    }));
  };

  const Option = forwardRef(
    ({ question, option, onOptionClick, onClick }, ref) => {
      const Icon = option.icon;

      const isSelected = () => {
        if (
          option.id === "select_date" &&
          typeof formData[question.id] === "object"
        ) {
          return true;
        }
        return formData[question.id] === option.title;
      };

      return (
        <StyledOption
          className={isSelected() ? "selected" : ""}
          onClick={() => {
            onOptionClick();
            onClick && onClick();
          }}
          type="button"
          ref={ref}
        >
          {Icon && (
            <div className="mb-3">
              <Icon />
            </div>
          )}
          <p className="text-center">{option.title}</p>
        </StyledOption>
      );
    }
  );

  return (
    <form onSubmit={handleSubmit}>
      <StyledSlider {...sliderSettings} ref={sliderRef}>
        <Container>
          <Row>
            <Col lg={8} className="mx-auto">
              <div className="text-center">
                <h2 className="h4">
                  {intl.formatMessage({ id: "lets_begin" })}
                </h2>
                <h2 className="h4 mb-5">
                  {intl.formatMessage({ id: "get_to_know" })}
                </h2>
              </div>
              {contactData.map(input => (
                <StyledInput
                  key={input.id}
                  type={input.type}
                  placeholder={input.placeholder}
                  name={input.placeholder}
                  value={formData[input.id]}
                  onChange={e =>
                    setFormData(prevData => ({
                      ...prevData,
                      [input.id]: e.target.value,
                    }))
                  }
                  className="mb-4"
                  required
                />
              ))}
              <div className="text-center mt-5">
                {slider && formData.name && formData.phone && formData.email && (
                  <Button onClick={() => slider.slickGoTo(1)} type="button">
                    {intl.formatMessage({ id: "next" })}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        {questions.map(question => (
          <Container key={question.id}>
            <input
              type="hidden"
              name={question.title}
              value={formData[question.id] || ""}
              required
            />
            <h2 className="h4 text-center mb-5">{question.title}</h2>
            <Row className="justify-content-center">
              {question.options.map(option => {
                return (
                  <Col key={option.id} xs={6} lg={4} className="mb-3">
                    {option.date ? (
                      <DatePicker
                        className="w-100"
                        wrapperClassName="w-100"
                        selected={formData.date || new Date()}
                        popperPlacement="auto"
                        onChange={date => {
                          console.log(typeof date, date);
                          setFormData(prevData => ({
                            ...prevData,
                            date,
                            deadline: date,
                          }));
                          slider.slickNext();
                        }}
                        customInput={
                          <Option
                            question={question}
                            option={option}
                            onOptionClick={() => onClick(question, option)}
                          />
                        }
                      />
                    ) : (
                      <Option
                        question={question}
                        option={option}
                        onOptionClick={() => onClick(question, option)}
                      />
                    )}
                  </Col>
                );
              })}
            </Row>
            {question.submitBtn && (
              <div className="text-center mt-5">
                <Button type="submit" disabled={state.submitting}>
                  {question.submitBtn.text}
                </Button>
              </div>
            )}
          </Container>
        ))}
        <Container className="text-center">
          <HappinessIcon />
          <h2 className="mt-4 mb-5">
            {intl.formatMessage({ id: "thank_you" })}
          </h2>
          <p>{intl.formatMessage({ id: "thank_you_msg_line_1" })}</p>
          <p>{intl.formatMessage({ id: "thank_you_msg_line_2" })}</p>
        </Container>
      </StyledSlider>
      {currentSlide > 0 && (
        <BackBtn
          onClick={() => slider?.slickPrev()}
          className="py-5"
          type="button"
        >
          <Back />
        </BackBtn>
      )}
    </form>
  );
};

export default Questionnaire;

import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { injectIntl, changeLocale, Link } from "gatsby-plugin-intl";
import styled from "styled-components";
import Headroom from "react-headroom";
import addToMailchimp from "gatsby-plugin-mailchimp";

import Hamburger from "../images/menu.inline.svg";
import Logo from "../images/logo.inline.svg";
import Close from "../images/close.inline.svg";
import { StyledButton as Button } from "./Button";

const languages = ["es", "en"];

const StyledButton = styled.button`
  position: relative;
  &.current {
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #000;
      width: 100%;
      height: 3px;
    }
  }
`;

const MenuWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: #fff;
  z-index: 1999;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out;
  &.opened {
    transform: translateY(0);
  }
  a .h1 {
    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }
  .h1 {
    @media (max-width: 767px) {
      font-size: 1.5rem;
    }
  }
  & > span {
    background-color: #cdcfd0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    &:nth-of-type(1) {
      left: 15px;
      @media (min-width: 768px) {
        left: 100px;
      }
    }
    &:nth-of-type(2) {
      left: 0;
      right: 0;
      margin: auto;
    }
    &:nth-of-type(3) {
      right: 15px;
      @media (min-width: 768px) {
        left: 100px;
      }
    }
  }
  .close-btn {
    position: absolute;
    left: 15px;
    top: 30px;
    width: 40px;
    svg {
      width: 100%;
      height: auto;
    }
  }
  .menu-item {
    letter-spacing: 0.2em;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: translateX(5px);
    }
  }
  .social-item {
    opacity: 0.4;
    transition: all 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
  input {
    display: block;
    width: 100%;
    background: transparent;
    border: none;
    border-bottom: solid 1px #000;
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem;
    &::placeholder {
      color: #000;
    }
  }
`;

const LanguageLink = ({ locale, current }) => {
  return (
    <StyledButton
      className={`${
        current ? "current " : ""
      }bg-transparent border-0 text-uppercase`}
      onClick={() => {
        changeLocale(locale);
      }}
    >
      {locale}
    </StyledButton>
  );
};

const Header = ({ intl }) => {
  const { locale } = intl;
  const [menuOpened, setMenuOpened] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [submitting, setSubmitting] = useState(false);
  const [formMsg, setFormMsg] = useState(null);
  const menuItems = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: intl.formatMessage({ id: "services" }),
      url: "/servicios",
    },
    {
      text: intl.formatMessage({ id: "projects" }),
      url: "/proyectos",
    },
    {
      text: "Blog",
      url: "/blog",
    },
    {
      text: intl.formatMessage({ id: "contact" }),
      url: "/contacto",
    },
  ];

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitting(true);
    addToMailchimp(formData.email, { FNAME: formData.name }).then(res => {
      setFormMsg(res.msg);
      setSubmitting(false);
    });
  };

  return (
    <>
      <Headroom>
        <Container fluid className="py-4">
          <Row className="align-items-center">
            <Col xs={2} md={3}>
              <button
                className="border-0 p-0 bg-transparent w-100 text-left"
                onClick={() => {
                  setMenuOpened(true);
                }}
              >
                <Hamburger className="img-fluid" />
              </button>
            </Col>
            <Col xs={1} className="d-md-none" />
            <Col xs={6} className="text-center">
              <Link to="/" className="w-100">
                <Logo className="img-fluid" />
              </Link>
            </Col>
            <Col xs={3} className="text-right">
              {languages.map(language => (
                <LanguageLink
                  key={language}
                  locale={language}
                  current={locale === language}
                />
              ))}
            </Col>
          </Row>
        </Container>
      </Headroom>
      <MenuWrapper
        className={`d-flex flex-column justify-content-center${
          menuOpened ? " opened" : ""
        }`}
      >
        <span></span>
        <span></span>
        <span></span>
        <button
          className="close-btn bg-transparent border-0"
          onClick={() => {
            setMenuOpened(false);
          }}
        >
          <Close />
        </button>
        <Container>
          <Row className="align-items-center">
            <Col lg={5}>
              <div className="mb-3 mb-lg-5">
                {menuItems.map(menuItem => (
                  <Link
                    key={menuItem.text}
                    className="d-flex mb-2 menu-item"
                    to={menuItem.url}
                  >
                    <h3 className="text-uppercase text-dark h1">
                      {menuItem.text}
                    </h3>
                  </Link>
                ))}
              </div>
              <a
                href="mailto:hola@metagrama.mx"
                className="text-dark d-block mb-2 social-item"
              >
                hola@metagrama.mx
              </a>
              {/* <a
                href="https://www.facebook.com/metagramamx/"
                className="text-dark d-block mb-2 social-item"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
              <a
                href="https://www.instagram.com/metagramamx/"
                target="_blank"
                rel="noreferrer"
                className="text-dark d-block social-item"
              >
                Instagram
              </a> */}
            </Col>
            <Col lg={5} className="ml-auto mt-5 mt-lg-0">
              <h2 className="text-uppercase h1 mb-3 mb-lg-5">
                {intl.formatMessage({ id: "subscribe" })}
              </h2>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder={intl.formatMessage({ id: "name" })}
                  value={formData.name}
                  onChange={e =>
                    setFormData(prevData => ({
                      ...prevData,
                      name: e.target.value,
                    }))
                  }
                />
                <input
                  type="email"
                  placeholder={intl.formatMessage({ id: "email" })}
                  value={formData.email}
                  onChange={e =>
                    setFormData(prevData => ({
                      ...prevData,
                      email: e.target.value,
                    }))
                  }
                />
                {formMsg && <p className="mb-4">{formMsg}</p>}
                <Button type="submit" color="#000" disabled={submitting}>
                  {intl.formatMessage({ id: "subscribe_btn" })}
                </Button>
              </form>
            </Col>
          </Row>
        </Container>
      </MenuWrapper>
    </>
  );
};

export default injectIntl(Header);

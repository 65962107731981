import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

import { FormModalContext } from "../../providers/FormModalProvider";
import Questionnaire from "./Questionnaire";
import Close from "../../images/close.inline.svg";

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: ${props => props.theme.colors.lightgray};
  }
`;

const FormModal = () => {
  const { modalState, setModalState } = useContext(FormModalContext);

  return (
    <StyledModal
      show={modalState.isOpen}
      onHide={() =>
        setModalState(prevState => ({ ...prevState, isOpen: false }))
      }
      size="xl"
      centered
    >
      <Modal.Body>
        <div className="text-right">
          <button
            onClick={() =>
              setModalState(prevState => ({ ...prevState, isOpen: false }))
            }
            className="border-0 bg-transparent mb-5"
          >
            <Close />
          </button>
        </div>
        <Questionnaire type={modalState.type} />
      </Modal.Body>
    </StyledModal>
  );
};

export default FormModal;

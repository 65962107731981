import React, { createContext, useState } from "react";

export const FormModalContext = createContext(null);

export const FormModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: "project",
  });

  return (
    <FormModalContext.Provider value={{ modalState, setModalState }}>
      {children}
    </FormModalContext.Provider>
  );
};

import styled from "styled-components";
import { Link } from "gatsby-plugin-intl";

const StyledLink = styled(Link)`
  color: ${props => (props.color ? props.color : props.theme.colors.gray)};
  &:before {
    content: "(";
  }
  &:after {
    content: ")";
  }
`;

export const StyledButton = styled.button`
  color: ${props => (props.color ? props.color : props.theme.colors.gray)};
  background: transparent;
  border: none;
  &:before {
    content: "(";
  }
  &:after {
    content: ")";
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ExternalLink = styled.a`
  color: ${props => (props.color ? props.color : props.theme.colors.gray)};
  display: inline-block;
  &:before {
    content: "(";
  }
  &:after {
    content: ")";
  }
`;

export default StyledLink;

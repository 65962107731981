import React from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { Reset } from "styled-reset";
import { ParallaxProvider } from "react-scroll-parallax";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "react-datepicker/dist/react-datepicker.css";
import "@fontsource/space-grotesk/400.css";
import "@fontsource/space-grotesk/500.css";
import "@fontsource/space-grotesk/600.css";

import Header from "./Header";
import Footer from "./Footer";
import FormModal from "./FormModal";
import { FormModalProvider } from "../providers/FormModalProvider";

const GlobalStyles = createGlobalStyle`
  body, html {
    font-family: 'Space Grotesk', sans-serif;
    letter-spacing: 0.1em;
  }
  p {
    line-height: 1.5;
    font-size: 18px;
  }
  button, a {
    cursor: pointer;
  }
  a {
    display: inline-block;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
  .popover.custom-popover {
    width: 400px;
    max-width: 100%;
  }
`;

const theme = {
  colors: {
    gray: "#6D6E71",
    lightgray: "#F6F6F6",
  },
};

const Wrapper = styled.div`
  position: relative;
  & > span {
    background-color: #cdcfd0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    &:nth-of-type(1) {
      left: 15px;
      @media (min-width: 768px) {
        left: 100px;
      }
    }
    &:nth-of-type(2) {
      left: 0;
      right: 0;
      margin: auto;
    }
    &:nth-of-type(3) {
      right: 15px;
      @media (min-width: 768px) {
        right: 100px;
      }
    }
  }
`;

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Reset />
      <GlobalStyles />
      <FormModalProvider>
        <ParallaxProvider>
          <Wrapper className="pb-5">
            <span></span>
            <span></span>
            <span></span>
            <Header />
            {children}
          </Wrapper>
        </ParallaxProvider>
        <Footer />
        <FormModal />
      </FormModalProvider>
    </ThemeProvider>
  );
};

export default Layout;

import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { useIntl } from "gatsby-plugin-intl";
import addToMailchimp from "gatsby-plugin-mailchimp";

import { StyledButton } from "./Button";
import FooterLogo from "../images/animated-logo.gif";
import { FormModalContext } from "../providers/FormModalProvider";

const Wrapper = styled.div`
  background-color: #000;
`;

const Input = styled.input`
  background: transparent;
  border: none;
  color: #fff;
  border-bottom: solid 1px #fff;
  display: block;
  width: 100%;
  padding-bottom: 5px;
  &::placeholder {
    color: #fff;
  }
`;

const Logo = styled.img`
  width: 180px;
  height: auto;
  max-width: 100%;
`;

const Footer = () => {
  const intl = useIntl();
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [formMsg, setFormMsg] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { setModalState } = useContext(FormModalContext);
  const date = new Date();
  const year = date.getFullYear();

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitting(true);
    addToMailchimp(formData.email, { FNAME: formData.name }).then(res => {
      setFormMsg(res.msg);
      setSubmitting(false);
    });
  };

  return (
    <Wrapper className="py-5 text-white position-relative">
      <Container className="pt-5">
        <Row>
          <Col lg={3} className="text-center text-lg-left mb-4 mb-lg-0">
            <Logo src={FooterLogo} alt="Metagrama" className="img-fluid" />
          </Col>
          <Col lg={9}>
            <Row>
              <Col lg={5} className="text-center text-lg-left">
                <a
                  href="mailto:hola@metagrama.mx"
                  className="text-white d-block mb-2"
                >
                  hola@metagrama.mx
                </a>
                {/* <a href="tel:6561019642" className="text-white d-block mb-2">
                  656 101 96 42
                </a>
                <a
                  href="https://www.facebook.com/metagramamx/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white d-block mb-2"
                >
                  Fb. @metagrama.mx
                </a>
                <a
                  href="https://www.instagram.com/metagramamx/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white d-block mb-5"
                >
                  In. @metagrama.mx
                </a> */}
                <h4 className="text-uppercase">
                  {intl.formatMessage({ id: "start_project_with_us" })}
                </h4>
                <StyledButton
                  color="#fff"
                  className="mt-3"
                  onClick={e => {
                    e.preventDefault();
                    setModalState({
                      isOpen: true,
                      type: "project",
                    });
                  }}
                >
                  {intl.formatMessage({ id: "interested" })}
                </StyledButton>
              </Col>
              <Col
                lg={6}
                className="ml-auto mt-5 mt-lg-0 text-center text-lg-left"
              >
                <h4 className="text-uppercase mb-2">
                  {intl.formatMessage({ id: "subscribe" })}
                </h4>
                <p className="mb-4">
                  {intl.formatMessage({ id: "subscribe_message" })}
                </p>
                <form onSubmit={handleSubmit}>
                  <Input
                    placeholder={intl.formatMessage({ id: "name" })}
                    className="mb-3"
                    value={formData.name}
                    onChange={e =>
                      setFormData(prevData => ({
                        ...prevData,
                        name: e.target.value,
                      }))
                    }
                  />
                  <Input
                    placeholder={intl.formatMessage({ id: "email" })}
                    className="mb-4"
                    value={formData.email}
                    onChange={e =>
                      setFormData(prevData => ({
                        ...prevData,
                        email: e.target.value,
                      }))
                    }
                  />
                  {formMsg && <p className="mb-4 text-white">{formMsg}</p>}
                  <StyledButton color="#fff" disabled={submitting}>
                    {intl.formatMessage({ id: "subscribe_btn" })}
                  </StyledButton>
                </form>
              </Col>
            </Row>
          </Col>
        </Row>
        <p className="text-center mt-5 pt-5">
          Metagrama, {year} <br />{" "}
          {intl.formatMessage({ id: "rights_reserved" })}
        </p>
      </Container>
    </Wrapper>
  );
};

export default Footer;

import { useIntl } from "gatsby-plugin-intl";

import House from "../../images/house-outline.inline.svg";
import OfficeBuilding from "../../images/office-building.inline.svg";
import BigOfficeBuilding from "../../images/office-building-big.inline.svg";
import ShoppingBag from "../../images/shopping-bag.inline.svg";
import ArGlasses from "../../images/ar-glasses.inline.svg";
import Engineer from "../../images/engineer.inline.svg";
import Shovel from "../../images/shovel.inline.svg";
import Brickwall from "../../images/brickwall.inline.svg";
import Calendar from "../../images/calendar.inline.svg";
import Flexible from "../../images/flexible.inline.svg";
import Square from "../../images/square.inline.svg";
import Cube from "../../images/invert.inline.svg";
import ImageGallery from "../../images/image-gallery.inline.svg";
import Panoramic from "../../images/panoramic.inline.svg";
import Video from "../../images/online-video.inline.svg";
import VrGlasses from "../../images/vr-glasses.inline.svg";
import Touchscreen from "../../images/touch-screen.inline.svg";
import Whatsapp from "../../images/whatsapp-logo.inline.svg";
import Mail from "../../images/mail.inline.svg";

const useQuestions = () => {
  const intl = useIntl();

  const projectQuestionsStart = [
    {
      id: "type",
      title: intl.formatMessage({ id: "what_type_of_project" }),
      options: [
        {
          id: "residential",
          title: intl.formatMessage({ id: "residential" }),
          icon: House,
        },
        {
          id: "vertical-housing",
          title: intl.formatMessage({ id: "vertical_housing" }),
          icon: OfficeBuilding,
        },
        {
          id: "multiple-use",
          title: intl.formatMessage({ id: "multiple_use" }),
          icon: BigOfficeBuilding,
        },
        {
          id: "comercial",
          title: intl.formatMessage({ id: "comercial" }),
          icon: ShoppingBag,
        },
        {
          id: "visualization",
          title: intl.formatMessage({ id: "visualization" }),
          icon: ArGlasses,
        },
        {
          id: "other",
          title: intl.formatMessage({ id: "other" }),
          icon: Engineer,
        },
      ],
    },
  ];

  const projectConstructionQuestions = [
    {
      id: "terrain_area",
      title: intl.formatMessage({ id: "terrain_area" }),
      options: [
        {
          id: "100-500",
          title: intl.formatMessage({ id: "100_to_500_m2" }),
        },
        {
          id: "501-1000",
          title: intl.formatMessage({ id: "501_to_1000_m2" }),
        },
        {
          id: "1000+",
          title: intl.formatMessage({ id: "more_than_1000_m2" }),
        },
      ],
    },
    {
      id: "terrain_conditions",
      title: intl.formatMessage({ id: "terrain_conditions" }),
      options: [
        {
          id: "no-construction",
          title: intl.formatMessage({ id: "no_construction" }),
          icon: Shovel,
        },
        {
          id: "under_construction",
          title: intl.formatMessage({ id: "under_construction" }),
          icon: Brickwall,
        },
      ],
    },
    {
      id: "project_size",
      title: intl.formatMessage({ id: "project_size" }),
      options: [
        {
          id: "small",
          title: intl.formatMessage({ id: "small" }),
          icon: House,
        },
        {
          id: "medium",
          title: intl.formatMessage({ id: "medium" }),
          icon: OfficeBuilding,
        },
        {
          id: "large",
          title: intl.formatMessage({ id: "large" }),
          icon: BigOfficeBuilding,
        },
      ],
    },
  ];

  const visualizationQuestions = [
    {
      id: "information",
      title: intl.formatMessage({ id: "what_information_do_you_have" }),
      options: [
        {
          id: "2d-file",
          title: intl.formatMessage({ id: "2d_file" }),
          icon: Square,
        },
        {
          id: "3d-model",
          title: intl.formatMessage({ id: "3d_model" }),
          icon: Cube,
        },
      ],
    },
    {
      id: "formats",
      title: intl.formatMessage({ id: "what_formats_do_you_need" }),
      options: [
        {
          id: "2d-rendering",
          title: intl.formatMessage({ id: "2d_rendering" }),
          icon: ImageGallery,
        },
        {
          id: "360-rendering",
          title: intl.formatMessage({ id: "360_rendering" }),
          icon: Panoramic,
        },
        {
          id: "cinematic-video",
          title: intl.formatMessage({ id: "cinematic_video" }),
          icon: Video,
        },
        {
          id: "vr",
          title: intl.formatMessage({ id: "vr" }),
          icon: VrGlasses,
        },
        {
          id: "interactive-software",
          title: intl.formatMessage({ id: "interactive_software" }),
          icon: Touchscreen,
        },
      ],
    },
  ];

  const projectFinalQuestions = [
    {
      id: "deadline",
      title: intl.formatMessage({ id: "project_deadline" }),
      options: [
        {
          id: "select_date",
          title: intl.formatMessage({ id: "select_date" }),
          icon: Calendar,
          date: true,
        },
        {
          id: "flexible_date",
          title: intl.formatMessage({ id: "im_flexible" }),
          icon: Flexible,
        },
      ],
    },
    {
      id: "contact",
      title: intl.formatMessage({ id: "how_we_contact_you" }),
      submitBtn: {
        text: intl.formatMessage({ id: "conclude" }),
      },
      options: [
        {
          id: "whatsapp",
          title: "Whatsapp",
          icon: Whatsapp,
        },
        {
          id: "email-contact",
          title: intl.formatMessage({ id: "email_long" }),
          icon: Mail,
        },
      ],
    },
  ];

  const investorQuestions = [
    {
      id: "terrain_area",
      title: intl.formatMessage({ id: "terrain_area" }),
      options: [
        {
          id: "100-500",
          title: intl.formatMessage({ id: "100_to_500_m2" }),
        },
        {
          id: "501-1000",
          title: intl.formatMessage({ id: "501_to_1000_m2" }),
        },
        {
          id: "1000+",
          title: intl.formatMessage({ id: "more_than_1000_m2" }),
        },
      ],
    },
    {
      id: "terrain_conditions",
      title: intl.formatMessage({ id: "terrain_conditions" }),
      options: [
        {
          id: "no-construction",
          title: intl.formatMessage({ id: "no_construction" }),
          icon: Shovel,
        },
        {
          id: "under_construction",
          title: intl.formatMessage({ id: "under_construction" }),
          icon: Brickwall,
        },
      ],
    },
    {
      id: "contact",
      title: intl.formatMessage({ id: "how_we_contact_you" }),
      submitBtn: {
        text: intl.formatMessage({ id: "conclude" }),
      },
      options: [
        {
          id: "whatsapp",
          title: "Whatsapp",
          icon: Whatsapp,
        },
        {
          id: "email-contact",
          title: intl.formatMessage({ id: "email_long" }),
          icon: Mail,
        },
      ],
    },
  ];

  return {
    projectQuestionsStart,
    projectConstructionQuestions,
    visualizationQuestions,
    projectFinalQuestions,
    investorQuestions,
  };
};

export default useQuestions;
